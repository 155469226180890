import { Component, OnInit } from '@angular/core';
import AOS from "aos"
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})

export class WelcomeComponent implements OnInit {
  // RANDOM IMAGE
  readonly img_array=[
    '../assets/img/banner/2.png',
    // '../assets/img/banner/1.jpeg',
    // '../assets/img/banner/1.jpeg',
  ];
  
  
    // get a random index
    random_index=Math.floor(Math.random() * this.img_array.length);
    // get an image at the random_index
    selected_image=this.img_array[this.random_index];
    // display the image




  constructor() {
    localStorage.removeItem("pdf_code");
    localStorage.removeItem('form_id');
   }
// public siteKey:any
  ngOnInit(): void {
    window.scrollTo(0,0);
    // AOS.init();
  }
  
  // resolved(CaptchaResponse:string){
  //   this.captcha=CaptchaResponse;
  //   console.log("resolved catcha with response:",this.captcha)

  // }
  
 
  // siteKey:string="6LfXlM4gAAAAAEi39ubHW5JpOKe8NC1UAzf77xeE"
}
