import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { TravellersService } from "../travellers/travellers.service";
import {User_data} from './../models/travellers'
@Component({
  selector: 'app-finalisee',
  templateUrl: './finalisee.component.html',
  styleUrls: ['./finalisee.component.scss']
})
export class FinaliseeComponent implements OnInit {
list;
code;
form_id

  constructor(
    private router: Router,
    private travellersService: TravellersService,
  ) { }

  ngOnInit(): void {
      // remove storage
      sessionStorage.removeItem("Infoform-data");
      sessionStorage.removeItem("Travelform-data");
      sessionStorage.removeItem("medicalform-data");
  
  
      this.code = localStorage.getItem("pdf_code");
      this.form_id = localStorage.getItem("form_id");

        this.travellersService.get_traveller_data( this.form_id).subscribe(
      (response) =>{

        if(response.status=="SUCCESS"){
          // console.log("sucess alert")
        }
        
          // alert("verifier le code et le numero de passeport")
      
        
        // this.the_traveller=response
        // console.log(this.the_traveller)
        // console.log("THE EDITED TRAVELLETTTTT",response); 
      }, 
      (error)=>{
        // this.toastr.error('', 'kkkkkkkkkkkkkkkkkk',{
        //   timeOut: 3000,
        //   positionClass: 'toast-bottom-right',
        // });
          // console.log("error in the request", error);
          // this.toastr.error(error);
     
      },
      ()=>{
            // console.log("Done getting the traveller");
          }
    
          
        )  
  
    // console.log("ODE PDF ID",this.code)
    // console.log("NGINIT BOOT")
    // window.open(
    //   `${environment.apiUrl}` +
    //     "/auth/mict/view_Ticket?pdf=" + '29647857022701',
    //     // respons.form.code,
    //   "_blank"
    // );
  //  let applicantId= localStorage.getItem("efes");
  //  console.log(applicantId)
  
  
  }



 generatePdf(){
  // this.router.navigate(['/view_Ticket/','29647857022701' ])
 
  if (this.code != '' && this.code != null && typeof this.code != "undefined") {
    this.list =JSON.parse(this.code!);
  }
  window.open(

    `${environment.apiUrl}` + `/app/public/immigration/${this.form_id}/downloadTicket`,
      //"/app/public/immigration/get-ticket/" + this.list,
      //  respons.form.code,
    "_blank"
  );
 }

 
}

