import {TestPCR} from './test';

//******FORM VARIABLE
export class Travellers {
  id: number;
  code: string;
  option:string;
  firstname: string;
  lastname: string;
  sexe: string;
  birthdate: string;
  phone: string;
  email: string;
  etatCivil:string;
  resident:string;
  parent_mineur_passp_number:string;
  nationalityAccomp:string;
  country_of_birth:string;
  passportNumber:string;
  passportExpDate:string;
  nationality: string;
  departementId:0;
  communeId:0;
  departementIdForNotHaitian:0;
  communeIdForNotHaitian:0;
  streetAddressForNotHaitian:string;
  street:string;
  city:string;
  codePostal:string;
  streetAddress:string;
  pontDEmbarqueMent:string;
  pontDEdesmbarqueMent:string;
  travelType: string;
  company: string
  flightNumber: string;
  provenanceCountry: string
  originCountry: string;
  visitedCountries: string[];
  travelMotif: string
  testDocumentFile: any[];
  testDocumentType: string;
  vaccinType:string;
  covidvacc:string;
  countryCodeInput:string;
  convidSymptome:string[];
  travelTypeId:0;
  dateVoyage:string;
  leavingCountry:string;
  typeGroundTranport:0;
  bus_compagny_name:string;
  emploi_organization:string;
  plaque:string;
  precision:string;
  Compagnyname_precision:string;
  compagnyVoyage:string;
  tiketNumber:string;
  dateVaccination:string;
  departementId_ArriverHaiti:0;
  communeId_ArriverHaiti:0;
  passportExpDate_acompagnateur:string;
  lien_avec_accompagnateur:string;
  terre_departure_port:string;
  terre_Entry_port:string;
  specify_hebergement:string
  // address_hebergement:string
  address_hebergement_ville:string;
  address_hebergement_rue:string;
  address_hebergement_region:string;
  addressResidence: string
  type_hebergement:string
  hotelname:string;
  port_Embarquement_mer:string;
  port_Debarquement_mer:string;
  hebergement_autre:string;

  // ====
  goodsDeclaration:boolean;
  moneyDeclaration:boolean;
  ViandeDeclaration:boolean;
  otherThingsDeclaration:boolean;
  moreInformationForGoods:tableType[];
  moreInformationForViande:tableType[];
  moreInformationForMoney:tableType[];
  moreInformationForOtherThings:tableType[];
  ninu:string
  // new field
  firstnameAcomp:string 
  lastnameAcomp:string
  program:string
  countryGiveVisa:string
  visaType:string
  arriving_date:string
  // new
  ori_haitienne:string
  Sejour:number 
  address_hebergement_numero:number

}



// API VARIABLE
export class The_traveller{
  id:number;
  accomdationType: string;
  airCompanyName: string;
  arrivingAirportName: string;
  childCountryOfBirth: string;
  childPassportExpiryDate:number;
  childPassportNumber: string;
  companionMaritalStatus: string;
  companionNationality: string;
  companionPassportExpiryDate:number;
  companionPassportNumber: string;
  companyName: string;
  countryOfBirth: string;
  countryOfDeparture: string;
  countryOfResidency: string;
  countryVisitedLastWeeks: string[];
  companionEmail:string;
  dateOfBirth: number;
  department: string;
  district: string;
  email: string;
  firstName: string;
  flightNumber: string;
  followingSymptoms: string[];
  formType: string;
  gender: string;
  hotelName: string;
  idCard: string;
  lastName: string;
  leavingAirportName: string;
  maritalStatus: string;
  // minorPassportExpiryDate:number;
  moreInformations: string;
  nationality: string;
  numberPlate: string;
  organizationName: string;
  passportExpirationDate:number;
  passportNumber: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  portOfEntry: string;
  portOfDeparture: string;
  route: string;
  ticketNumber: string;
  travelBy: string;
  travelDate:number;
  travelPurpose: string;
  travelWith: string;
  // yourAddress: string;
  // adding
  ninu:string;
  numberOfFamily:number;
  ///minor:string;//not save to db
  termCondition:number;//not save to db
  goodsDeclaration:boolean;
  moneyDeclaration:boolean;
  otherThingsDeclaration:boolean;
  plantsAnimalsDeclaration:boolean;

  moreInformationForGoods:tableType[];
  moreInformationForMoney:tableType[];
  moreInformationForPlantsAnimals:tableType[];
  moreInformationForOtherThings:tableType[];

  //address
  city:string;
  region:string;
  street:string;
  yourFriendship:string;

  // new variable
  visaType:string;
  countryIssuedVisa:string;
  companionFirstName:string;
  companionLastName:string;
  documentType:string;
  travelerEnteredCountry:number
  // new
  addressNumber:number
  isHaitianBorn:string
  numberOfStay:number
 
}
//  types
export class formInfo{
  addressResidence: string
  birthdate: string
  city: string
  codePostal: string
  communeIdForNotHaitian: string
  country_of_birth: string
  departementIdForNotHaitian: string
  email: string
  etatCivil: string
  firstname: string 
  lastname: string
  lien_avec_accompagnateur: string
  minor:string
  nationality: string
  ninu: string
  option: string
  parent_mineur_passp_number: string
  passportExpDate: string
  passportExpDate_acompagnateur: string
  passportNumber: string
  phone: string
  resident: string
  sexe: string
  street: string
  streetAddressForNotHaitian: string

}

export interface API_response_type {
  statusInt:number;
  status:string
  message:string
  data:The_traveller
}
export interface BusCompagnyName {
  id:number;
  name:string;
}

export interface FamilyMember {
  id:number;
  value:number;
}

export interface tableType {
  quantity:number;
  quality:number;
  description:string
}


export interface Ports {
  id:number;
  name:string;
}

export class User_data
{
    passeport:string;
    code:string;
}
export interface Motif {
  id:number;
  name:string;
}

export interface transType {
  id:number;
  name:string;
}

export interface humanitarianType {
  id:string;
  name:string;
}
export interface MoyenTrans {
  id:number;
  name:string;
}