// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlOld: 'https://efes.ayiti.digital/mspp_api/api/v1',
  // apiUrl:'http://157.90.141.58:9892',
  apiUrl:'https://eefs-admin-api.waysandmeanstechnology.com',

  // apiUrl: 'http://74.208.235.147:8393/api/v1',
  // API_PATH: 'http://74.208.235.147:8393/api/v1',
  // "https://efes.ayiti.digital/mspp_api/api/v1",
  // apiUrl: 'http://derl-service-api:8080/api/v1'
  // apiUrl: 'http://74.208.235.147:8393/api/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
