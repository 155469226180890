import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Travellers } from "../models/travellers";
import { environment } from "../../environments/environment";
import { from, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {User_data,The_traveller,API_response_type} from './../models/travellers'

@Injectable({
  providedIn: "root",
})
export class TravellersService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  

  constructor(public http: HttpClient) {}

  create(travelers): Observable<any> {
    // const headers = new HttpHeaders();
    // headers.append("Content-Type", "application/json");

    // let headers = new HttpHeaders({'Content-Type': 'application/json' });
    // let options = { headers: headers };
    //   const headers = new HttpHeaders();
    // headers.append("Access-Control-Allow-Origin: *", "application/json");

    // console.log("TEST1",travelers["pontDEmbarqueMent"]);
    // console.log("TEST2",travelers["pontDEdesmbarqueMent"]);



// =======================================================
    // const headers = { 'Content-Type': 'application/json' };
            // OU
    const headers= new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
// ==============================================================
    var dateOfBirth=new Date(travelers["birthdate"])
    var dateOfBirth_miliSecond=dateOfBirth.getTime()
    // ===
    var companionPassportExpiryDate=new Date(travelers["passportExpDate_acompagnateur"])
    var companionPassportExpiryDate_miliSecond=companionPassportExpiryDate.getTime()
    // ===
    var passportExpirationDate=new Date(travelers["passportExpDate"])
    var passportExpirationDate_miliSecond=passportExpirationDate.getTime()
    // ====
    var travelDate=new Date(travelers["dateVoyage"])
    var travelDate_miliSecond=travelDate.getTime()
    //=====
    var travelDate_arriving_date=new Date(travelers["arriving_date"])
    var arriving_date_miliSecond=travelDate_arriving_date.getTime()

    // ============================================================
    // FILTER TABLE ROW BEFORE SAVIBG
    var moreInfoGoods=travelers["moreInformationForGoods"].filter((obj:any)=> obj.quality!=0)
    var moreInfoMoney=travelers["moreInformationForMoney"].filter((obj:any)=> obj.quality!=0)
    var moreInfoOtherThings=travelers["moreInformationForOtherThings"].filter((obj:any)=> obj.quality!=0)
    var moreInfoViande=travelers["moreInformationForViande"].filter((obj:any)=> obj.quality!=0)
    



    //console.log("save table without empty row",moreInfoGoods)






    //console.log("TESTING ARRIVING DATE",arriving_date_miliSecond)
    // THE OBJECT WE SEND(body content)
    var voyagers = {
      accomdationType: (travelers["type_hebergement"]=="autreHeb")? travelers["specify_hebergement"]:travelers["type_hebergement"],
      airCompanyName: (travelers["compagnyVoyage"]=="AutreAirLineCompagny")? travelers["compagnyVoyage_precision"]:travelers["compagnyVoyage"],
      arrivingAirportName: travelers["pontDEdesmbarqueMent"],
      childCountryOfBirth: travelers["country_of_birth"],//duplicated
      childPassportExpiryDate: passportExpirationDate_miliSecond || 0,//duplicated
      childPassportNumber:  travelers["passportNumber"],//duplicated
      city: travelers["address_hebergement_ville"],
      companionEmail:travelers["emailCompagnon"],
      companionMaritalStatus: travelers["etatCivil"],//duplicated
      companionNationality: travelers["nationalityAccomp"],
      companionPassportExpiryDate: companionPassportExpiryDate_miliSecond || 0,//723497346000,
      companionPassportNumber: travelers["parent_mineur_passp_number"],
      companyName: (travelers["bus_compagny_name"]=="100")? travelers["Compagnyname_precision"]:travelers["bus_compagny_name"],
      countryOfBirth: travelers["country_of_birth"],
      countryOfDeparture:travelers["leavingCountry"],
      countryOfResidency: travelers["resident"],
      countryVisitedLastWeeks: travelers["visitedCountries"] || null,//to accept empty list
      dateOfBirth:dateOfBirth_miliSecond || 0,// 625986000000,
      department: travelers["departementId_ArriverHaiti"] || null,
      district: travelers["communeId_ArriverHaiti"] || null,
      email:travelers["email"],// "test@gmail.com",//
      firstName: travelers["firstname"],
      flightNumber: travelers["flightNumber"],
      followingSymptoms: travelers["convidSymptome"] || null,//to accept empty list
      formType: travelers["option"],
      gender: travelers["sexe"],
      goodsDeclaration: travelers["goodsDeclaration"],
      hotelName: (travelers["hotelname"]=="100")? travelers["specify_AutreHotelName"]:travelers["hotelname"],
      idCard: travelers["ninu"],
      lastName: travelers["lastname"],
      leavingAirportName: travelers["pontDEmbarqueMent"],
      maritalStatus: travelers["etatCivil"],
      moneyDeclaration: travelers["moneyDeclaration"],
      moreInformationForGoods:moreInfoGoods,
      moreInformationForMoney:moreInfoMoney,
      moreInformationForOtherThings:moreInfoOtherThings,
      moreInformationForPlantsAnimals:moreInfoViande,
      moreInformations: null,
      nationality: travelers["nationality"],
      ninu:travelers["ninu"],
      numberOfFamily:travelers["numberOfFamily"],
      numberPlate: travelers["plaque"],
      organizationName:travelers["emploi_organization"], 
      otherThingsDeclaration:travelers["otherThingsDeclaration"],
      passportExpirationDate: passportExpirationDate_miliSecond || 0,//1702643353000,
      passportNumber: travelers["passportNumber"],
      phoneNumber: travelers["phone"],
      phoneNumberCountryCode: travelers["countryCodeInput"],
      plantsAnimalsDeclaration: travelers["ViandeDeclaration"],
      portOfDeparture: travelers["terre_departure_port"],
      portOfEntry: travelers["terre_Entry_port"],
      region: travelers["address_hebergement_region"],
      route: travelers["travelTypeId"],
      street: travelers["address_hebergement_rue"],
      ticketNumber: travelers["tiketNumber"],
      travelBy: (travelers["typeGroundTranport"]=="100")?travelers["precision"]:travelers["typeGroundTranport"],
      travelDate: travelDate_miliSecond || 0,
      travelPurpose: travelers["travelMotif"],
      travelWith: (travelers["minor"]=="other")? travelers["lien_avec_accompagnateur"]:travelers["minor"],
      yourAddress:null,
      yourFriendship: (travelers["minor"]=="other")? travelers["lien_avec_accompagnateur"]:null,
      // new attibutes
      visaType:travelers["visaType"],
      countryIssuedVisa:travelers["countryGiveVisa"],
      companionFirstName: travelers["firstnameAcomp"],
      companionLastName: travelers["lastnameAcomp"],
      documentType:travelers["program"],
      travelerEnteredCountry:arriving_date_miliSecond || 0,
      // new field
      addressNumber:travelers["address_hebergement_numero"],
      isHaitianBorn:(travelers["ori_haitienne"]=='ORI_HAITIENNE'? true:false),
      numberOfStay:travelers["Sejour"],

    };

    const body=JSON.stringify(voyagers);
    // console.log("THE TRAVELLER OBJECT SENDED TO BE SAVE ",body)
    return this.http.post<any>(
       `${environment.apiUrl}`+"/app/public/immigration/api/submit-form",body,{ headers }
    // 'https://graceful-jeans-worm.cyclic.app/form',body,{ headers }
      );
  }












  // travellers(
  //   pageNumber: number,
  //   pageSize: number,
  //   query?: string
  // ): Observable<any> {
  //   const currentPage = pageNumber - 1;

  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");

  //   let params = new HttpParams();
  //   params = params.append("pageSize", pageSize.toString());
  //   params = params.append("pageNumber", currentPage.toString());

  //   let request;

  //   if (!query) {
  //     request = this.http.post<any>(
  //       `${environment.apiUrl}` + "/transactionList",
  //       {},
  //       { headers, params }
  //     );
  //   } else {
  //     params = params.append("query", query);
  //     request = this.http.post<any>(
  //       `${environment.apiUrl}` + "/transactionList",
  //       {},
  //       { headers, params }
  //     );
  //   }

  //   return request;
  // }

  // travellersDetails(id) {
  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   return this.http.get<any>(
  //     `${environment.apiUrl}` + "/applicantDetails/" + id,
  //     {}
  //   );
  // }

  // testResult(id) {
  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   // headers.append('Access-Control-Allow-Origin: *', 'application/json');
  //   headers.append("responseType:", "arraybuffer" as "json");
  //   return this.http.get<any>(`${environment.apiUrl}` + "/viewtestPCR/" + id, {
  //     headers,
  //   });
  // }

  // checkDocumentSerial(serial): Observable<boolean> {
  //   const headers = new HttpHeaders();
  //   const params = new HttpParams().set("numeroPiece", serial);
  //   return this.http.post<any>(
  //     `${environment.apiUrl}` + "/auth/checkNumeroPiece",
  //     {},
  //     { headers, params }
  //   );
  // }

  // updateAddress(communeId, departementId, documentSerial, streetAddress) {
  //   const headers = new HttpHeaders();
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   return this.http.post<any>(
  //     `${environment.apiUrl}` + "/auth/updateAddress",
  //     { communeId, departementId, documentSerial, streetAddress },
  //     { headers }
  //   );
  // }

  // accept(id: number, validate: boolean) {
  //   const headers = new HttpHeaders();
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   return this.http.get<any>(
  //     `${environment.apiUrl}` + "/accepter/" + id + "/" + validate,
  //     { headers }
  //   );
  // }

  // refuse(id: number, validate: boolean, reason: string) {
  //   const headers = new HttpHeaders();
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   return this.http.get<any>(
  //     `${environment.apiUrl}` + "/reject/" + id + "/" + validate + "/" + reason,
  //     { headers }
  //   );
  // }

  // identityPicture(id) {
  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   // const params = new HttpParams().set('applicantId', id);
  //   return this.http.get<any>(`${environment.apiUrl}` + "/viewIdentity/" + id, {
  //     headers,
  //   });
  // }

  // temperature(id, temperature, mesure): Observable<any> {
  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   const params = new HttpParams()
  //     .set("applicantId", id)
  //     .set("temperature", temperature)
  //     .set("temperatureType", mesure);
  //   return this.http.put<any>(
  //     `${environment.apiUrl}` + "/addTemperature",
  //     {},
  //     { headers, params }
  //   );
  // }

  // note(id, remarque): Observable<any> {
  //   const headers = new HttpHeaders();
  //   headers.append(
  //     "Authorization",
  //     "Bearer " + JSON.parse(localStorage.getItem("currentUser"))["accessToken"]
  //   );
  //   // headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   headers.append('content-Type:','application/json');
  //   const params = new HttpParams()
  //     .set("applicantId", id)
  //     .set("remarque", remarque);
  //   return this.http.put<any>(
  //     `${environment.apiUrl}` + "/addRemarque",
  //     {},
  //     { headers, params }
  //   );
  // }

  // CreateTravellers(traveller): Observable<The_traveller> {
  //   // const headers = new HttpHeaders();
  //   // headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   // headers.append("Content-Type", "application/json");
  //   // return this.http.post(`${environment.apiUrl}` + '/saveApplicant', travellers, {headers});
  //   return this.http.post<The_traveller>(`${environment.apiUrl}`,JSON.stringify(traveller),
  //     this.httpOptions
  //     )
  //     .pipe(catchError(this.errorHandler));
  // }

  // sendOTP(email: string) {
  //   const headers = new HttpHeaders();
  //   headers.append("Access-Control-Allow-Origin: *", "application/json");
  //   const params = new HttpParams().set("email", email);
  //   return this.http.post<any>(
  //     `${environment.apiUrl}` + "/auth/emailConfirm",
  //     {},
  //     { headers, params }
  //   );
  // }

  // checkOTP(otp): Observable<boolean> {
  //   const headers = new HttpHeaders();
  //   const params = new HttpParams().set("otp", otp);
  //   return this.http.post<any>(
  //     `${environment.apiUrl}` + "/auth/otp",
  //     {},
  //     { headers, params }
  //   );
  // }

  // errorHandler(error) {
  //   let errorMessage = "";
  //   if (error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   return throwError(errorMessage);
  // }


  // 

  // /auth/mict/details/17797529386177/RTR443455 
  get_traveller_data(data:User_data):Observable<API_response_type>{
    // /console.log(`${environment.apiUrl}` + "/auth/mict/details/" + `${data.code}/${data.passeport}`)
    // console.log("the urm totla",`${environment.API_PATH}` + "/auth/mict/details/"+`${data.code}/${data.passeport}`)
    return this.http.get<API_response_type>(
      `${environment.apiUrl}` + "/app/public/immigration/api/get-form-details/?formReferenceCode="+`${data.code}`+"&passportNumber="+`${data.passeport}`,  

      // 'https://graceful-jeans-worm.cyclic.app/api/'+`${data.code}`+'/'+`${data.passeport}`
    )
  }
  




  update_traveller_data(travelers:any,formId:number):Observable<any>{
    var dateOfBirth=new Date(travelers["birthdate"])
    var dateOfBirth_miliSecond=dateOfBirth.getTime()
  // ===
  var companionPassportExpiryDate=new Date(travelers["passportExpDate_acompagnateur"])
  var companionPassportExpiryDate_miliSecond=companionPassportExpiryDate.getTime()
  // ===
  var passportExpirationDate=new Date(travelers["passportExpDate"])
  var passportExpirationDate_miliSecond=passportExpirationDate.getTime()
  // ====
  var travelDate=new Date(travelers["dateVoyage"])
  var travelDate_miliSecond=travelDate.getTime()
   //=====
   var travelDate_arriving_date=new Date(travelers["arriving_date"])
   var arriving_date_miliSecond=travelDate_arriving_date.getTime()
// ============================================================
    // FILTER TABLE ROW BEFORE SAVIBG
    var moreInfoGoods=travelers["moreInformationForGoods"].filter((obj:any)=> obj.quality!=0)
    var moreInfoMoney=travelers["moreInformationForMoney"].filter((obj:any)=> obj.quality!=0)
    var moreInfoOtherThings=travelers["moreInformationForOtherThings"].filter((obj:any)=> obj.quality!=0)
    var moreInfoViande=travelers["moreInformationForViande"].filter((obj:any)=> obj.quality!=0)

   var voyagers = {
    accomdationType: (travelers["type_hebergement"]=="autreHeb")? travelers["specify_hebergement"]:travelers["type_hebergement"],
    airCompanyName: (travelers["compagnyVoyage"]=="AutreAirLineCompagny")? travelers["compagnyVoyage_precision"]:travelers["compagnyVoyage"],
    arrivingAirportName: travelers["pontDEdesmbarqueMent"],
    childCountryOfBirth: travelers["country_of_birth"],//duplicated
    childPassportExpiryDate: passportExpirationDate_miliSecond || 0,//duplicated
    childPassportNumber:  travelers["passportNumber"],//duplicated
    city: travelers["address_hebergement_ville"],
    companionEmail:travelers["emailCompagnon"],
    companionMaritalStatus: travelers["etatCivil"],//duplicated
    companionNationality: travelers["nationalityAccomp"],
    companionPassportExpiryDate: companionPassportExpiryDate_miliSecond || 0,//723497346000,
    companionPassportNumber: travelers["parent_mineur_passp_number"],
    companyName: (travelers["bus_compagny_name"]=="100")? travelers["Compagnyname_precision"]:travelers["bus_compagny_name"],
    countryOfBirth: travelers["country_of_birth"],
    countryOfDeparture:travelers["leavingCountry"],
    countryOfResidency: travelers["resident"],
    countryVisitedLastWeeks: travelers["visitedCountries"] || null,//to accept empty list
    dateOfBirth:dateOfBirth_miliSecond || 0,// 625986000000,
    department: travelers["departementId_ArriverHaiti"] || null,
    district: travelers["communeId_ArriverHaiti"] || null,
    email:travelers["email"],// "test@gmail.com",//
    firstName: travelers["firstname"],
    flightNumber: travelers["flightNumber"],
    followingSymptoms: travelers["convidSymptome"] || null,//to accept empty list
    formType: travelers["option"],
    gender: travelers["sexe"],
    goodsDeclaration: travelers["goodsDeclaration"],
    hotelName: (travelers["hotelname"]=="100")? travelers["specify_AutreHotelName"]:travelers["hotelname"],
    idCard: travelers["ninu"],
    lastName: travelers["lastname"],
    leavingAirportName: travelers["pontDEmbarqueMent"],
    maritalStatus: travelers["etatCivil"],
    moneyDeclaration: travelers["moneyDeclaration"],
    moreInformationForGoods:moreInfoGoods,
    moreInformationForMoney:moreInfoMoney,
    moreInformationForOtherThings:moreInfoOtherThings,
    moreInformationForPlantsAnimals:moreInfoViande,
    moreInformations: null,
    nationality: travelers["nationality"],
    ninu:travelers["ninu"],
    numberOfFamily:travelers["numberOfFamily"],
    numberPlate: travelers["plaque"],
    organizationName:travelers["emploi_organization"], 
    otherThingsDeclaration:travelers["otherThingsDeclaration"],
    passportExpirationDate: passportExpirationDate_miliSecond || 0,//1702643353000,
    passportNumber: travelers["passportNumber"],
    phoneNumber: travelers["phone"],
    phoneNumberCountryCode: travelers["countryCodeInput"],
    plantsAnimalsDeclaration: travelers["ViandeDeclaration"],
    portOfDeparture: travelers["terre_departure_port"],
    portOfEntry: travelers["terre_Entry_port"],
    region: travelers["address_hebergement_region"],
    route: travelers["travelTypeId"],
    street: travelers["address_hebergement_rue"],
    ticketNumber: travelers["tiketNumber"],
    travelBy: (travelers["typeGroundTranport"]=="100")?travelers["precision"]:travelers["typeGroundTranport"],
    travelDate: travelDate_miliSecond || 0,
    travelPurpose: travelers["travelMotif"],
    travelWith: (travelers["minor"]=="other")? travelers["lien_avec_accompagnateur"]:travelers["minor"],
    yourAddress:null,
    yourFriendship: (travelers["minor"]=="other")? travelers["lien_avec_accompagnateur"]:null,
    // new attibutes
    visaType:travelers["visaType"],
    countryIssuedVisa:travelers["countryGiveVisa"],
    companionFirstName: travelers["firstnameAcomp"],
    companionLastName: travelers["lastnameAcomp"],
    documentType:travelers["program"],
    travelerEnteredCountry:arriving_date_miliSecond || 0,
    // new field
// new field
addressNumber:travelers["address_hebergement_numero"],
isHaitianBorn:(travelers["ori_haitienne"]=='ORI_HAITIENNE'? true:false),
numberOfStay:travelers["Sejour"],
  };

    const body=JSON.stringify(voyagers);

    // console.log("THE TRAVELLER OBJECT SENDED TO BE UPDATED",voyagers)
    // /console.log(`${environment.apiUrl}` + "/auth/mict/details/" + `${data.code}/${data.passeport}`)
    // console.log("the urm totla",`${environment.API_PATH}` + "/auth/mict/details/"+`${data.code}/${data.passeport}`)
    return this.http.put<any>(
      `${environment.apiUrl}`+"/app/public/immigration/api/" +`${formId}`+"/update-form",voyagers
      // 'https://graceful-jeans-worm.cyclic.app/modifier/'+`${formId}`,voyagers
    )
  }
}


// NOTE
/*
const headers= new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

return this.http.put<ICorporateStatementInfo>('/api/v1/statements/category-statement/' + corporate_statement_link_id, body, {headers})

IMPLEMENT THE BUSSNES LOGIC
*/