

import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators, FormGroup } from '@angular/forms';
// import {Edit_data} from "../models/edit_form"

// import { ToastrService } from 'ngx-toastr';
import { TravellersService } from "./../travellers/travellers.service";
import {User_data,The_traveller} from './../models/travellers'
import { Travellers } from "../models/travellers";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: 'app-intro-form',
  templateUrl: './intro-form.component.html',
  styleUrls: ['./intro-form.component.scss']
})


 

export class IntroFormComponent implements OnInit {
 
  // create an object
  user_data= new User_data()
  the_traveller:The_traveller

// ATRIBUTES
edith_form = this.formBuilder.group({
  passeport :['', Validators.required],
  code:['', Validators.required]
  })

// CONSTRUCTOR
constructor(
              private formBuilder: FormBuilder,
              private travellers: TravellersService,
              private route:Router,
              // private toastr: ToastrService,
              private travellersService: TravellersService,
              private router: Router,
              private activatedRoute:ActivatedRoute //because this component can be executed by the route
              ) {}


              
// METHODS
ngOnInit(): void {
  window.scrollTo(0,0);
  //  this.onSubmit()
    // remove storage
    sessionStorage.removeItem("Infoform-data");
    sessionStorage.removeItem("Travelform-data");
    sessionStorage.removeItem("medicalform-data");
}


// onSubmit():void{
//     this.travellersService.get_traveller_data( this.edith_form.value).subscribe(
//       (response) =>{
//         // console.log(response)
//         if(response.applicantId!==null){
//           localStorage.setItem('efes',JSON.stringify(response))
//           this.toastr.success('', 'Toastr fun!',{
//             timeOut: 3000,
//             positionClass: 'toast-bottom-right',
//           });
//           this.route.navigate(['/formulaire'])
//         }else{
//           this.toastr.error('', 'verifier votre credential',{
//             timeOut: 3000,
//             positionClass: 'toast-bottom-right',
//           });
       
//           // alert("verifier le code et le numero de passeport")
//         }
        
//         // this.the_traveller=response
//         // console.log(this.the_traveller)
//         // console.log("THE EDITED TRAVELLETTTTT",response); 
//       }, 
//       (error)=>{
//           console.log("error in the request", error);
//           this.toastr.error(error);
//       },
//       ()=>{
//             console.log("Done getting the traveller");
//           }
    
// )
  
//    }



onSubmit():void{
  // here we passe "code" and "passport number" parameters into the url, to get use from other components
  this.router.navigate(['/modifier-form/', this.edith_form.value.code, this.edith_form.value.passeport])
  //spinner
  const load=document.getElementById('load')
  load.style.display='grid'
}

// onSubmit3(){
//   this.route.navigate(['/modifier-form/', this.edith_form.value.code, this.edith_form.value.passeport])
// }    






// onSubmit():void{
//   const code = this.edith_form.get("code").value;
//   const data={
//     code:'29282911521386',
//     passeport:'HG5637373 '
//   }
//   // const passport = this.edith_form.get("passeport").value;
//     // this.request_param.code="";
//     // this.request_param.passeport=passport ;
//     // get traveller data
//   // ===================================================================
//   this.travellersService.get_traveller_data( this.user_data).subscribe(
//     (response) =>{
      
//       if(response.applicantId!==null){
//         this.fillFormInfo(response)
//         this.toastr.success('', 'Toastr fun!',{
//           timeOut: 3000,
//           positionClass: 'toast-bottom-right',
//         });











// onSubmit():void{

  // this.activatedRoute.params.subscribe(
  //   (val:User_data)=>{
  //     console.log("TEST API",val)
  //     this.user_data=val
      
  //   })




//     this.travellersService.get_traveller_data( this.edith_form.value).subscribe(
//       (response) =>{
//         console.log(response)
//         if(response.applicantId!==null){
//           localStorage.setItem('efes',JSON.stringify(response))
//           this.toastr.success('', 'Toastr fun!',{
//             timeOut: 3000,
//             positionClass: 'toast-bottom-right',
//           });
//         }else{
//           this.toastr.error('', 'verifier votre credential',{
//             timeOut: 3000,
//             positionClass: 'toast-bottom-right',
//           });
        
//           // alert("verifier le code et le numero de passeport")
//         }
        
//         // this.the_traveller=response
//         // console.log(this.the_traveller)
//         // console.log("THE EDITED TRAVELLETTTTT",response); 
//       }, 
//       (error)=>{
//           console.log("error in the request", error);
//           this.toastr.error(error);
//       },
//       ()=>{
//             console.log("Done getting the traveller");
//           }
    
// )





    // this.travellers.edit_form(
    //                               data
    //                             ).subscribe(
    //                                       (response) =>{
    //                                           console.log("THE EDITED TRAVELLETTTTT",response);
    //                                           console.log('kfhasdkfhsdhfksdhfh',JSON.stringify(response))
    //                                           localStorage.setItem('efes',JSON.stringify(response))
    //                                           this.route.navigate(['/formulaire'])
    //                                       }, 
    //                                       (error)=>{
    //                                           console.log("error in the request", error);
    //                                       },
    //                                       ()=>{
    //                                             console.log("Done getting the traveller");
    //                                           }
    //                                       )
  
                                                                     //};
  
   


  //  fillFormInfo(traveller:The_traveller){
  //   this.infosForm.get('nationality').setValue(traveller.p_nationalite)
  //   this.infosForm.get('firstname').setValue(traveller.p_preNom)
  //   this.infosForm.get('lastname').setValue(traveller.p_nom)
  //   this.infosForm.get('birthdate').setValue(traveller.p_dateNaissance)
  //   this.infosForm.get('etatCivil').setValue(traveller.p_etatCivil)
  //   this.infosForm.get('resident').setValue(traveller.p_paysResidence)
  //   // this.infosForm.get('phones').setValue(traveller.p_phone)
  //   this.infosForm.get('sexe').setValue(traveller.p_sexe)
  //   this.infosForm.get('cityOfBirth').setValue(traveller.p_villeNaissance)
  //   this.infosForm.get('country_of_birth').setValue(traveller.p_paysNaissance)
  //   this.infosForm.get('passportNumber').setValue(traveller.p_passeportNumber)
  //   this.infosForm.get('email').setValue(traveller.p_email)
  //   this.infosForm.get('street').setValue(traveller.p_ville)
  //   this.infosForm.get('city').setValue(traveller.p_etat)
  //   this.infosForm.get('addressResidence').setValue(traveller.p_rue)
  //   this.infosForm.get('departementId').setValue(traveller.p_depart)
  //   this.infosForm.get('communeId').setValue(traveller.p_comm)
  //   this.infosForm.get('streetAddress').setValue(traveller.p_adresseHaiti)
  //   this.infosForm.get('passportExpDate').setValue(traveller.p_passeportExpiration)
  // }


  // convenience getter for easy access to form fields
  get e() { return this.edith_form.controls; }

get passeport(){
  return  this.edith_form.get("passeport");
}
get code(){
  return  this.edith_form.get("code");
}


}

  

